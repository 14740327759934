<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
          size="40"
          :src="getApiFile(userData.profilePicture)"
          :text="avatarText(userData.name)"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
      >
        <feather-icon
            v-if="!userData.name"
            icon="UserIcon"
            size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
        :to="{ name: 'account'}"
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
      />
      <span>Hesabım</span>
    </b-dropdown-item>

    <b-dropdown-divider/>

    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
      />
      <span>Çıkış Yap</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import {initialAbility} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import {avatarText, getApiFile} from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      getApiFile,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageMerchantCompanyId)
      localStorage.removeItem('userData')

      this.$ability.update(initialAbility)

      this.$router.push({name: 'auth-login'})
    },
  },
}
</script>
