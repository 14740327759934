export default [
    {
        header: 'GENEL',
    },
    {
        title: 'Dashboard',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        header: 'İŞLEMLER',
    },
    {
        title: 'Aktivite İşlemleri',
        route: 'activity-list',
        icon: 'CodesandboxIcon',
    },
    {
        header: 'TANIMLAR',
    },
    {
        title: 'Müşteri Tanımları',
        route: 'definition-customer-list',
        icon: 'BookIcon',
    },
    {
        title: 'Plasiyer Tanımları',
        route: 'definition-salesman-list',
        icon: 'UsersIcon',
    },
    {
        title: 'Bölge Tanımları',
        route: 'definition-region-list',
        icon: 'MapPinIcon',
    },
    {
        title: 'Sektör Tanımları',
        route: 'definition-sector-list',
        icon: 'PackageIcon',
    },
    {
        header: 'SİSTEM',
    },
    {
        route: 'system-settings',
        title: 'Ayarlar',
        icon: 'SettingsIcon',
    },
    {
        route: 'system-role-list',
        title: 'Rol Yönetimi',
        icon: 'SlidersIcon',
    },
    {
        route: 'system-user-list',
        title: 'Kullanıcı Yönetimi',
        icon: 'UsersIcon',
    },
    {
        route: 'system-notification-list',
        title: 'Bildirim Yönetimi',
        icon: 'BellIcon',
    },
    {
        route: 'system-page-list',
        title: 'Sayfa Yönetimi',
        icon: 'LayoutIcon',
    },
    {
        route: 'system-definition-list',
        title: 'Tanım Yönetimi',
        icon: 'LayersIcon',
    },
]
